import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class PrefillDefaultsService {
	constructor() {}

	addPrefillIndicationsDriverHistory(driverHistoryPayload) {
		let prefill = [];

		for (let i = 0; i < driverHistoryPayload.length; i++) {
			let id = driverHistoryPayload[i].Id;
			const AccidentsAttributes = ['Description', 'DateOfAccident', 'AccidentPDAmount', 'AccidentVehicleInvolved'];
			const LossesAttributes = [
				'AutoLossesDate',
				'AutoLossesDescription',
				'AutoLossesAmount',
				'AutoLossesVehicleInvolved',
			];
			const ViolationsAttributes = ['DateOfViolation', 'Description'];

			for (let j = 0; j < driverHistoryPayload[i].Accidents.length; j++) {
				prefill = prefill.concat(
					this.addAttributesPrefill(id, driverHistoryPayload[i].Accidents[j].Id, AccidentsAttributes, 'Accidents')
				);
			}

			for (let j = 0; j < driverHistoryPayload[i].Losses.length; j++) {
				prefill = prefill.concat(
					this.addAttributesPrefill(id, driverHistoryPayload[i].Losses[j].Id, LossesAttributes, 'Losses')
				);
			}

			for (let j = 0; j < driverHistoryPayload[i].Violations.length; j++) {
				prefill = prefill.concat(
					this.addAttributesPrefill(id, driverHistoryPayload[i].Violations[j].Id, ViolationsAttributes, 'Violations')
				);
			}
		}

		return prefill;
	}

	addAttributesPrefill(idDriver, idAtt, attributesArray, type) {
		let prefill = [];

		for (let i = 0; i < attributesArray.length; i++) {
			let item = { id: '', source: '' };
			item.id = "Drivers[?(@Id=='" + idDriver + "')]." + type + "[?(@Id=='" + idAtt + "')]." + attributesArray[i];
			item.source = 'Defaults';
			prefill.push({ ...item });
		}
		return prefill;
	}

	addPrefillIndications(id, keys) {
		return keys.map((x) => {
			return { id: `Drivers[?(@Id=='${id}')].${x}`, source: 'Defaults' };
		});
	}
}
